let ProgressBar = require('progressbar.js');

function setListProgressCircle(progress) {
    $('#list-progress').html('');

    const list_bar = new ProgressBar.Circle('#list-progress', {
        color: '#ea377a',
        trailColor: '#8890ad8c',
        strokeWidth: 4,
        trailWidth: 2,
        duration: 1500,
        easing: 'easeOut',
        text: {
            value: Math.floor(parseFloat(progress) * 100) + "%",
            className: "list-progress-label"
        }
    });

    if (!$('#list-progress-circle').hasClass('d-none')) {
        list_bar.set(0);
        list_bar.animate(progress);
    }
}

onmount("#stats-switcher", function() {
  $(this).on('click', function() {
    const user_id = $(this).attr('user_id');
    const curr_user_id = $(this).attr('curr_user_id');
    const list_id = $(this).attr('list_id');

    $.ajax({
      type: "PUT",
      url: '/api/list/stats/',
      data: {user_id: user_id, list_id: list_id},
      success(res) {
        setListProgressCircle(res.progress);
        $('#list-progress-avg-rating #avg-rating').html(res.avg_played_rating);
      }
    });
    $(this).html("See your stats")
    $(this).attr('user_id', curr_user_id);
    $(this).attr('curr_user_id', user_id);
  });
});

onmount('#list-progress-circle', function(){
    setListProgressCircle($('#list-progress').attr('progress'));
});

let progressBars = [];

onmount('.list-progress-expand', function() {
  $(this).on('click', function() {

    if($('#list-progress-extra').hasClass("d-none")) {
        $.each(progressBars, function(index, list_bar) {
            list_bar['bar'].set(0);
            list_bar['bar'].animate(list_bar['progress']); 
        });
    }

    $('.list-progress-expand i').toggleClass("rotated");
    $('#list-progress-extra').toggleClass("d-none");
    $('.card.game-cover .status-overlay').toggleClass('d-none');
    $('.note-with-status').toggleClass('shift-down');
  });
});

onmount('.list-progress-type', function() {
    $(this).html('');
    const list_bar = new ProgressBar.Line('.list-progress-type#' + $(this).attr('id'), {
        color: $(this).attr('color'),
        trailColor: '#8890ad8c',
        strokeWidth: 2,
        trailWidth: 2,
        duration: 750,
        easing: 'easeOut'
    });

    progressBars.push({'bar': list_bar, 'progress': $(this).attr('progress')});
});

onmount('#list-progress-mastered', function() {
    $('#list-progress-mastered').html('');

    const list_bar = new ProgressBar.Line('#list-progress-mastered', {
        color: '#43b94f',
        trailColor: '#8890ad8c',
        strokeWidth: 2,
        trailWidth: 2,
        duration: 1500,
        easing: 'easeOut'
    });

    list_bar.set(0);
    list_bar.animate($('#list-progress-mastered').attr('progress'));
});