import { Chart, Tooltip, PieController, ArcElement } from 'chart.js';

Chart.register(
	PieController,
	ArcElement,
	Tooltip
);

const tippy = require('tippy.js').default;
import {createSingleton} from 'tippy.js';
let ProgressBar = require('progressbar.js');
import { setAsLoading } from 'helpers.js';

let tippyInstances;
let singleton;

function setYearlyTooltips(){
	if (tippyInstances) {
		$.each(tippyInstances, function(index, val){
			val.destroy();
		});
	}
	tippyInstances = tippy('.yearly-graph-col');
	if (singleton) {
		singleton.setInstances(tippyInstances);
	} else {
		singleton = createSingleton(tippyInstances, {
			moveTransition: 'transform 0.2s ease-out',
			placement: 'right',
			theme: 'backloggd-stats',
			allowHTML: true,
		});
	}
}

function arraySum(arr) {
	let total = 0;

	for (let i = 0; i < arr.length; i++) {
		total += arr[i] << 0;
	}
	return total;
}

onmount('#platforms-graph', function() {
	let platformInstances = tippy('#platforms-graph .graph-bar-row');
	createSingleton(platformInstances, {
		moveTransition: 'transform 0.2s ease-out',
		placement: 'left',
		theme: 'backloggd-stats',
		allowHTML: true,
	});
});

onmount('#genres-graph', function() {
	let platformInstances = tippy('#genres-graph .graph-bar-row');
	createSingleton(platformInstances, {
		moveTransition: 'transform 0.2s ease-out',
		placement: 'left',
		theme: 'backloggd-stats',
		allowHTML: true,
	});
});

onmount('.stats-progress-line', function(){
	$(this).html('');

    const list_bar = new ProgressBar.Line($(this)[0], {
        color: '#BADEFC',
        from: { color: '#BADEFC' },
        to: { color: '#ea377a' },
        trailColor: '#242832',
        strokeWidth: 2,
        trailWidth: 2,
        duration: 1500,
        easing: 'easeOut',
        step: (state, bar) => {
	    	bar.path.setAttribute('stroke', state.color);
	  	}
    });

    list_bar.set(0);
    list_bar.animate($(this).attr('progress'));
});

onmount('.stats-progress-circle', function(){
	$(this).html('');

    const list_bar = new ProgressBar.Circle($(this)[0], {
        color: '#ea377a',
        trailColor: '#8890ad8c',
        strokeWidth: 10,
        trailWidth: 2,
        duration: 1500,
        easing: 'easeOut'
    });

    list_bar.set(0);
    list_bar.animate($(this).attr('progress'));
});

onmount("#ownership-chart", function(){
	$.ajax({
		type: 'POST',
		url: '/stats/' + $('#static-info').attr('user_id') + "/ownership",
		success(res) {
			let ctx = $('#ownership-chart');

			if (arraySum(res.data.datasets[0].data) < 10) {
				$('#ownership-title, #ownership-chart-section').addClass('d-none');
			}

			let pieChart = new Chart(ctx, {
				type: 'pie',
				data: res.data,
				options: {
					legend: {
						position: 'bottom',
						onClick: null,
						labels: {
							fontColor: '#fff',
						}
					},
					tooltips: {
						cornerRadius: 4,
						callbacks: {
					      label: function(tooltipItem, data) {
					        let dataset = data.datasets[tooltipItem.datasetIndex];
					        const meta = dataset._meta[Object.keys(dataset._meta)[0]];
					        const total = meta.total;
					        const currentValue = dataset.data[tooltipItem.index];
					        const percentage = parseFloat((currentValue/total*100).toFixed(1));
					        return currentValue + ' (' + percentage + '%)';
					      },
					      title: function(tooltipItem, data) {
					        return data.labels[tooltipItem[0].index];
					      }
					    }
					},
					plugins: {
						labels: {
							render: 'label',
							fontColor: "#fff",
							overlap: false,
							position: 'border',
						}
					}
				}
			});
		}
	});
});

onmount("#medium-chart", function(){
	$.ajax({
		type: 'POST',
		url: '/stats/' + $('#static-info').attr('user_id') + "/medium",
		success(res) {
			let ctx = $('#medium-chart');

			if (arraySum(res.data.datasets[0].data) < 10) {
				$('#medium-chart-section, #medium-title').addClass('d-none');
			}

			let pieChart = new Chart(ctx, {
				type: 'pie',
				data: res.data,
				options: {
					legend: {
						position: 'bottom',
						onClick: null,
						labels: {
							fontColor: '#fff',
						}
					},
					tooltips: {
						cornerRadius: 4,
						callbacks: {
					      label: function(tooltipItem, data) {
					        let dataset = data.datasets[tooltipItem.datasetIndex];
					        const meta = dataset._meta[Object.keys(dataset._meta)[0]];
					        const total = meta.total;
					        const currentValue = dataset.data[tooltipItem.index];
					        const percentage = parseFloat((currentValue/total*100).toFixed(1));
					        return currentValue + ' (' + percentage + '%)';
					      },
					      title: function(tooltipItem, data) {
					        return data.labels[tooltipItem[0].index];
					      }
					    }
					},
					plugins: {
						labels: {
							render: 'label',
							fontColor: "#fff",
							overlap: false,
							position: 'border',
						}
					}
				}
			});
		}
	});
});

// Currently not live or used in prod
onmount('#game-journal-chart', function(){
	$.ajax({
		type: 'GET',
		url: '/stats/game/' + $(this).attr('game_id') + "/journal",
		success(res) {
			let ctx = $('#game-journal-chart');

			let lineChart = new Chart(ctx, {
				type: 'line',
				data: res.data,
				options: {
					legend: {
						display: false
					},
					tooltips: {
						cornerRadius: 4,
						callbacks: {
					      label: function(tooltipItem, data) {
					        let dataset = data.datasets[tooltipItem.datasetIndex];
					        const currentValue = dataset.data[tooltipItem.index];
					        return currentValue || 0;
					      },
					      title: function(tooltipItem, data) {
					        return data.labels[tooltipItem[0].index];
					      }
					    }
					},
					scales: {
						yAxes: [{
							ticks: {
								callback: function(val, index, ticks) {
									return index % 2 == 0 ? val : undefined
								}
							},
							gridLines: {
								display: true,
								color: '#242832'
							}
						}],
						xAxes: [{
							ticks: {
								callback: function(val, index, ticks) {
									return index % 2 == 0 ? val : undefined
								}
							},
							gridLines: {
								display: false
							}
						}]
					},
					plugins: {
						labels: {
							render: 'label',
							fontColor: "#fff",
							overlap: false,
							position: 'border',
						}
					}
				}
			});
		}
	});
});

function setYearlyGraph(data_type) {
	$.ajax({
		type: 'POST',
		url: '/stats/' + $('#static-info').attr('user_id') + '/yearly/' + data_type,
		success(res) {
			$('#yearly-graph .graph-bar-bkg').each(function(){
				const year = $(this).attr('year');
				$(this).height(((res.data[year] ? (res.data[year] * (1 / res.max) * 100) : 0.25)).toString() + "%");
			});
			$('.yearly-graph-col').each(function(){
				const year = $(this).attr('year');
				let label = "<span class='main'>" + Math.floor(year) + "</span><br>";

				if(!res.data[year]) {
					$(this).addClass('empty-column');
				} else {
					$(this).removeClass('empty-column');
				}

				const gameStat = parseInt(res.data[year]) || 0;

				if(data_type == 'ratings_year') {
					label += "Avg Rating " + (res.data[year] ? (res.data[year] / 2).toFixed(2) : "-")
					$(this).children().attr('href', $(this).children().attr('ratings_url'));
				} else {
					label += gameStat + " Game" + (gameStat == 1 ? " " : "s ") + (data_type == "played_year" ? "Played" : (data_type == "release_year" ? "Released" : "Finished"))
					if(data_type == 'release_year') {
						$(this).children().attr('href', $(this).children().attr('release_url'));
					} else {
						$(this).children().attr('href', $(this).children().attr('finished_url'));
					}
				}

				$(this).attr('data-tippy-content', label);
			});
			setYearlyTooltips();
		}
	});
}

onmount("#yearly-graph", function(){
	setYearlyGraph($('#yearly-graph-nav .btn.toggled').attr("data-type"));
});

onmount('#stats-series-container', function(){
	$.ajax({
		type: 'GET',
		url: '/stats/' + $('#static-info').attr('user_id') + '/series/all'
	});
});

onmount('#series-progress-nav .btn', function(){
	$(this).on('click', function() {

		if ($(this).hasClass('toggled')) {
			return;
		}

		$('#series-progress-nav .btn').removeClass("toggled");
		$(this).addClass('toggled');

		const data_type = $(this).attr("data-type");

		if (data_type == 'completed_series') {
			$.ajax({
				type: 'GET',
				url: '/stats/' + $('#static-info').attr('user_id') + '/series/completed'
			});
		} else if (data_type == 'progress_series') {
			$.ajax({
				type: 'GET',
				url: '/stats/' + $('#static-info').attr('user_id') + '/series/progress'
			});
		} else {
			$.ajax({
				type: 'GET',
				url: '/stats/' + $('#static-info').attr('user_id') + '/series/all'
			});
		}
	});
});

onmount('#fav-company-nav .btn', function(){
	$(this).on('click', function() {

		if ($(this).hasClass('toggled')) {
			return;
		}

		$('#fav-company-nav .btn').removeClass("toggled");
		$(this).addClass('toggled');

		const data_type = $(this).attr("data-type");
		$('.company-stat-section').addClass('d-none');
		$('#company_' + data_type).removeClass("d-none");
	});
});

onmount('#yearly-graph-nav .btn', function(){
	$(this).on('click', function() {

		if ($(this).hasClass('toggled')) {
			return;
		}

		$('#yearly-graph-nav .btn').removeClass("toggled");
		$(this).addClass('toggled');

		const data_type = $(this).attr("data-type");
		setYearlyGraph(data_type);
	});
});

onmount('.log-status-card', function() {
	$(this).hover(function() {
		$('.log-status-card, .log-status.total-status-bar').addClass('disabled');
		$('.log-status.total-status-bar.' + $(this).attr('status')).removeClass('disabled')
		$(this).removeClass('disabled');
	}, function() {
		$('.log-status-card, .log-status.total-status-bar').removeClass('disabled');
	});
});

onmount('.log-status.total-status-bar', function() {
	$(this).hover(function() {
		$('.log-status-card, .log-status.total-status-bar').addClass('disabled');
		$('.log-status-card[status="' + $(this).attr('status') + '"]').removeClass('disabled')
		$(this).removeClass('disabled');
	}, function() {
		$('.log-status-card, .log-status.total-status-bar').removeClass('disabled');
	});
});

onmount('.game-status-card', function() {
	$(this).hover(function() {
		$('.game-status-card, .game-status.total-status-bar').addClass('disabled');
		$('.game-status.total-status-bar.' + $(this).attr('status')).removeClass('disabled')
		$(this).removeClass('disabled');
	}, function() {
		$('.game-status-card, .game-status.total-status-bar').removeClass('disabled');
	});
});

onmount('.game-status.total-status-bar', function() {
	$(this).hover(function() {
		$('.game-status-card, .game-status.total-status-bar').addClass('disabled');
		$('.game-status-card[status="' + $(this).attr('status') + '"]').removeClass('disabled')
		$(this).removeClass('disabled');
	}, function() {
		$('.game-status-card, .game-status.total-status-bar').removeClass('disabled');
	});
});