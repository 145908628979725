onmount('#patreon-connect', function(){
	$(this).on('click', function(){
		const client_id = $(this).attr('client_id');
		const redirect_uri = location.protocol + "//" + location.host + "/patreon/setup/";
		const scope = "identity"
		const patreon_url = "https://www.patreon.com/oauth2/authorize?response_type=code&client_id="

		window.location.href = patreon_url + client_id + "&redirect_uri=" + redirect_uri + "&scope=" + scope 
	});
});

onmount('#patreon-disconnect', function(){
	$(this).on('click', function(){
		$.ajax({
			method: 'DELETE',
			url: '/patreon/disconnect/',
			success(res) {
				window.location.reload();
			}
		});

		$(this).prop('disabled', true);
	});
});