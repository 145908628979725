onmount('.friend-btn.follow', function() {
	$(this).on('click', function() {
		$.ajax({
			type: 'POST',
			url: '/follow/',
			data: { user_id: $(this).attr('user_id')}
		});
	});
});

onmount('.friend-btn.unfollow-btn', function() {
	$(this).on('click', function() {
		$.ajax({
			type: 'DELETE',
			url: '/unfollow/',
			data: { user_id: $(this).attr('user_id')}
		});
	});
});