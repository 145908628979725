import { blConfirmation, setAsLoading } from 'helpers.js';

onmount('.remove-user-social-link', function(){
	$(this).on('click', function(){
		const link_id = $(this).attr('link_id');
		const user_id = $(this).attr('user_id');
		const link_type = $(this).attr('link_type');

		let btn = $(this);

		btn.prop('disabled', true);

		blConfirmation("Are you sure you want to remove this user's " + link_type +" link?",
			function(){
				btn.prop('disabled', false);
			},
			function(){
				$.ajax({
					type: 'DELETE',
					url: '/users/' + user_id + '/social_link/' + link_id,
					success(res) {
						toastr.success('Social link removed!');
						$('.row[social_link="' + link_id +'"]').remove();
					}
				});
			}, "Cancel", "Remove")

		
	});
});

onmount('#admin-api-refresh', function(){
	$(this).on('click', function(){
		const game_id = $(this).attr('game_id');

		$.ajax({
			type: 'POST',
			url: '/games-refresh/' + game_id,
			success(res) {
				window.location.reload();
			}
		})
	});
});

onmount('#editing-unlock', function(){
	$(this).on('click', function(){
		const review_id = $(this).attr('review_id');
		$(this).prop('disabled', true);

		$.ajax({
			type: "PUT",
			url: '/appeal/review/' + review_id + '/allow_edit/',
			data: { allow: true },
			success(res){
				toastr.success('Editing has been unlocked');
				window.location.reload();
			}
		})
	});
});

onmount('#editing-lock', function(){
	$(this).on('click', function(){
		const review_id = $(this).attr('review_id');
		$(this).prop('disabled', true);

		$.ajax({
			type: "PUT",
			url: '/appeal/review/' + review_id + '/allow_edit/',
			data: { allow: false },
			success(res){
				toastr.success('Editing has been locked');
				window.location.reload();
			}
		})
	});
});

onmount('#appeal-unlock', function(){
	$(this).on('click', function(){
		const review_id = $(this).attr('review_id');
		$(this).prop('disabled', true);

		$.ajax({
			type: "PUT",
			url: '/appeal/review/' + review_id + '/open/',
			success(res){
				toastr.success('Appeals have been unlocked');
				window.location.reload();
			}
		})
	});
});

onmount('#appeal-lock', function(){
	$(this).on('click', function(){
		const review_id = $(this).attr('review_id');
		$(this).prop('disabled', true);

		$.ajax({
			type: "PUT",
			url: '/appeal/review/' + review_id + '/close/',
			success(res){
				toastr.success('Appeals have been locked');
				window.location.reload();
			}
		})
	});
});

onmount('#admin-disconnect-patron', function(){
	$(this).on('click', function(){
		const user_id = $(this).attr('user_id');
		let btn = $(this);
		btn.prop('disabled', true);

		blConfirmation("Are you sure you want to disconnect this user's Patreon account?",
			function(){
				btn.prop('disabled', false);
			},
			function(){
				$.ajax({
		      type: "DELETE",
		      url: "/users/" + user_id + "/patreon",
		      success(res){
		        toastr.success('User patron association removed');
		      },
		      error(err, textStatus, errorThrown) {
		        toastr.success('Error removing user patron association: ' + errorThrown);
		      }
		    });
			}, "Cancel", "Remove")
	});
});

onmount('#remove-backlog-status', function(){
	$(this).on('click', function(){
		const user_id = $(this).attr('user_id');
		let btn = $(this);
		btn.prop('disabled', true);

		blConfirmation("Are you sure you want to remove the backlog status for every log this user has?",
			function(){
				btn.prop('disabled', false);
			},
			function(){
				$.ajax({
		      type: "DELETE",
		      url: "/users/" + user_id + "/logs/backlog",
		      success(res){
		        toastr.success('User backlog statuses removed');
		      },
		      error(err, textStatus, errorThrown) {
		        toastr.success('Error removing user backlog statuses: ' + errorThrown);
		      }
		    });
			}, "Cancel", "Remove")
	});
});

onmount('#remove-reviews', function(){
	$(this).on('click', function(){
		const user_id = $(this).attr('user_id');
		let btn = $(this);
		btn.prop('disabled', true);

		blConfirmation("Are you sure you want to delete every review written by this user?",
			function(){
				btn.prop('disabled', false);
			},
			function(){
				$.ajax({
		      type: "DELETE",
		      url: "/users/" + user_id + "/reviews",
		      success(res){
		        toastr.success('User reviews removed');
		      },
		      error(err, textStatus, errorThrown) {
		        toastr.success('Error removing user reviews: ' + errorThrown);
		      }
		    });
			}, "Cancel", "Destroy")
	});
});

onmount('#remove-review-likes', function(){
	$(this).on('click', function(){
		const user_id = $(this).attr('user_id');
		let btn = $(this);
		btn.prop('disabled', true);

		blConfirmation("Are you sure you want to delete every review like given by this user?",
			function(){
				btn.prop('disabled', false);
			},
			function(){
				$.ajax({
		      type: "DELETE",
		      url: "/users/" + user_id + "/review/likes",
		      success(res){
		        toastr.success('User review likes removed');
		      },
		      error(err, textStatus, errorThrown) {
		        toastr.success('Error removing user review likes: ' + errorThrown);
		      }
		    });
			}, "Cancel", "Destroy")
	});
});

onmount('#admin-destroy-user', function(){
	$(this).on('click', function(){
		const user_id = $(this).attr('user_id');
		let btn = $(this);
		btn.prop('disabled', true);

		blConfirmation("Are you sure you want to delete this user and all their data?",
			function(){
				btn.prop('disabled', false);
			},
			function(){
				$.ajax({
		      type: "DELETE",
		      url: "/users/" + user_id,
		      success(res){
		        toastr.success('User scheduled for removal');
		      },
		      error(err, textStatus, errorThrown) {
		        toastr.success('Error scheduling delete user job: ' + errorThrown);
		      }
		    });
			}, "Cancel", "Destroy")
	});
});

onmount('#admin-delete-list', function(){
	$(this).on('click', function(){
		const list_id = $(this).attr('list_id');

		blConfirmation("Are you sure you want to delete this list? This cannot be undone.",
			function(){},
			function(){
	    	$.ajax({
		      type: "DELETE",
		      url: "/api/list/" + list_id,
		      success(res){
		        toastr.success('List ID: ' + list_id + ' removed');
		      },
		      error(err, textStatus, errorThrown) {
		        toastr.success('Error deleted list: ' + errorThrown);
		      }
		    });
  		}, "Cancel", "Destroy");
	});
});

onmount('#unset-user-restriction', function(){
	$(this).on('click', function(){
		$.ajax({
			type: 'PATCH',
			url: '/admin/user/' + $(this).attr('user_id'),
			data: { length_hours: -1, restriction: null, reason: "" },
		});
	});
});

onmount('#set-user-restriction', function(){
	$(this).on('click', function(){
		if(!restrictionFilled()) {
			return;
		}

		const duration = $('#restriction-time-picker').val()[0];
		const restriction = $('#restriction-picker').val()[0];
		const reason = $('#restriction_reason').val();

		$.ajax({
			type: 'PATCH',
			url: '/admin/user/' + $(this).attr('user_id'),
			data: { length_hours: duration, restriction: restriction, reason: reason },
		});

		$('#set-user-restriction').prop('disabled', true);
	});
});

function restrictionFilled(){
	if($('#restriction_reason').val() && $('#restriction-picker').val() && $('#restriction-time-picker').val()) {
		return true;
	}
	return false;
}

onmount('#restriction-time-picker', function(){
	$(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
		if(restrictionFilled()) {
			$('#set-user-restriction').prop('disabled', false);
		} else {
			$('#set-user-restriction').prop('disabled', true);
		}
	})
});

onmount('#restriction-picker', function(){
	$(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
		if(restrictionFilled()) {
			$('#set-user-restriction').prop('disabled', false);
		} else {
			$('#set-user-restriction').prop('disabled', true);
		}
	})
});

onmount('#restriction_reason', function(){
	$(this).on('input', function(){
		if(restrictionFilled()) {
			$('#set-user-restriction').prop('disabled', false);
		} else {
			$('#set-user-restriction').prop('disabled', true);
		}
	});
});

onmount('#restriction-tooltip', function(){
    $($(this)[0]).popover({
        content: "<u><b>Restrictions</b></u><br><b>nocomments</b> - User can't create new comments<br><br><b>noreviews</b> - User can't create new comments or reviews<br><br><b>noratings</b> - User's ratings aren't counted (but still show up)<br><br><b>shadow</b> - User can't comment, review, or have ratings counted<br><br><b>banned</b> - User can't sign into site, ratings aren't counted or shown publicly, comments are shown as removed, and lists don't show on lists page",
        offset: '40.75%p',
        trigger: 'focus',
        html: true
    });
    $(this).on('click', function(){
        $(this).popover('toggle');
    });
});

onmount('#admin-award-badge', function(){
	$(this).on('click', function(){
		const val = $('#award-badge-field').val();
    const user_id = $(this).attr('user_id');

		$.ajax({
			type: "PUT",
			url: "/api/user/" + user_id + "/badge/" + val,
			success(res){
        toastr.success('Badge successfully awarded');
      },
      error(err, textStatus, errorThrown) {
        toastr.success('Error awarding badge: ' + errorThrown);
      }
		})
	});
});

onmount('#admin-user-nav .btn-link', function(){
	$(this).on('click', function(){
		$('#admin-user-nav .btn-link').removeClass('active');
		$(this).addClass('active');
		$('.admin-user-nav-container').addClass('d-none');
		const container = $(this).attr('container');
		$('#' + container).removeClass('d-none')
	});
});

onmount('#remove-avatar', function(){
	const user_id = $(this).attr('user_id');
	$(this).on('click', function(){
		$.ajax({
      type: "DELETE",
      url: "/users/" + user_id + "/avatar/",
      success(res){
        toastr.success('User avatar destroyed');
      },
      error(err, textStatus, errorThrown) {
        toastr.success('Error destroying user avatar: ' + errorThrown);
      }
    });
	});
});

onmount('#reset-username', function(){
	$(this).on('click', function(){
		const user_id = $(this).attr('user_id');
		$.ajax({
      type: "DELETE",
      url: "/users/" + user_id + "/username/",
      success(res){
        toastr.success('Username reset');
      },
      error(err, textStatus, errorThrown) {
        toastr.success('Error resetting username: ' + errorThrown);
      }
    });
	});
});

onmount('#reset-bio', function(){
	$(this).on('click', function(){
		const user_id = $(this).attr('user_id');
		$.ajax({
      type: "DELETE",
      url: "/users/" + user_id + "/bio/",
      success(res){
        toastr.success('Bio removed');
      },
      error(err, textStatus, errorThrown) {
        toastr.success('Error removing bio: ' + errorThrown);
      }
    });
	});
});


onmount('#reset-username-change', function(){
  $(this).on('click', function(){
    const val = $(this).val();
    const user_id = $(this).attr('user_id');

    $.ajax({
      type: "PATCH",
      url: "/users/" + user_id,
      data: { username_changed_at: null },
      success(res){
        toastr.success('Username timer reset');
      },
      error(err, textStatus, errorThrown) {
        toastr.success('Error resetting username timer: ' + errorThrown);
      }
    });
  });
});

var open_role_picker_confirmation = true;

onmount('#role-picker', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
    const val = $(this).val();
    const user_id = $(this).attr('user_id');

    if (open_role_picker_confirmation) {
	    blConfirmation("Are you sure you want to change this user's role?",
		    function(){
		    	open_role_picker_confirmation = false;
		    	$('#role-picker').selectpicker('val', previousValue);
		    },
		    function(){
		    	$.ajax({
			      type: "PATCH",
			      url: "/users/" + user_id,
			      data: { role: val },
			      success(res){
			        toastr.success('User role updated to ' + val);
			      },
			      error(err, textStatus, errorThrown) {
			        toastr.success('Error updating role: ' + errorThrown);
			      }
			    });
		    }
	    );
	  }

	  open_role_picker_confirmation = true;
  });
});

onmount('#perk-status-picker', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){
    let val = $(this).val();
    const user_id = $(this).attr('user_id');

    if (Array.isArray(val)) {
      val = val[0];
    }

    if (val === undefined) {
    	val = null;
    }

    $.ajax({
      type: "PATCH",
      url: "/users/" + user_id,
      data: { perk_status: val },
      success(res){
        toastr.success('User perks updated to ' + val);
      },
      error(err, textStatus, errorThrown) {
        toastr.success('Error updating perks: ' + errorThrown);
      }
    });
  });
});

onmount('.spam-ip-address', function(){
	$(this).on('click', function(){
		const val = $(this).attr('ip_address');
		$('#ip-spam-input-field').val(val);

		$.ajax({
			type: "GET",
			url: "/admin/load-spam-suspects/",
			data: { query: val }
		})
	});
});

onmount('#bulk-ban-spam-users', function(){
	$(this).on('click', function(){
		const val = $('#ip-spam-input-field').val();

		$.ajax({
			type: "DELETE",
			url: "/admin/ip-spam-destroy/",
			data: { query: val }
		})
	});
});

onmount('#bulk-ban-all-users', function(){
	$(this).on('click', function(){
		const val = $('#ip-spam-input-field').val();

		$.ajax({
			type: "DELETE",
			url: "/admin/ip-spam-destroy/",
			data: { query: val, skip_spam_check: true }
		})
	});
});

onmount('#maintenance-submit', function(){
	$(this).on('click', function(){
		const val = $('#maintenance-input-field').val();

		$.ajax({
			type: "PUT",
			url: "/admin/maintenance-warning/",
			data: { text: val }
		})
	});
});

onmount('#clear-cache-submit', function(){
	$(this).on('click', function(){
		const val = $('#clear-cache-input-field').val();

		$.ajax({
			type: "DELETE",
			url: "/admin/clear-cache/",
			data: { cache_key: val }
		})
	});
});

let search_timeout;
onmount('#similar_game_search', function(){
	$(this).on('input', function(){
		clearTimeout(search_timeout);
		search_timeout = setTimeout(function(){
			const query = $('#similar_game_search').val();
			const curr_game_id = $('#similar_game_search').attr('curr_game_id');

			if(query.length > 2) {
				$.ajax({
					type: 'GET',
					url: '/admin/search/game/' + query,
					data: { curr_game_id: curr_game_id },
					success(res) {
						$('#admin-similar-game-results').html(res);
						onmount();
					}
				})
			}

		}, 500);
	});
});

onmount('.merge-game-btn', function(){
	$(this).on('click', function(){

		let merge_btn = $(this);

		blConfirmation("Are you sure you want to merge this game?",
			function(){},
			function(){
				merge_btn.prop('disabled', true);
				merge_btn.html("Merging...");
				const game_id = merge_btn.attr('game_id');
				const new_game_id = merge_btn.attr('new_game_id');

				$.ajax({
					type: 'PATCH',
					url: '/games/' + game_id + '/transfer/' + new_game_id,
					success(res) {
						$.ajax({
							type: 'PUT',
							url: '/api/game/' + game_id + '/block/'
						});
						$.ajax({
							type: 'PUT',
							url: '/reports/close/game/' + game_id + '/',
							data: { report_type: 'Duplicate' },
							success(res) {
								window.location.reload();
							}
						});
					}
				})
			}, "Cancel", "Merge");
	});
});

onmount('.quick-remove-review', function(){
	$(this).on('click', function(){
		const review_id = $(this).attr('review_id');
		const remove_details = "Violates Terms of Service"
		let btn = $(this);

		if (review_id) {
			$.ajax({
        type: 'DELETE',
        url: '/admin/review/remove/' + review_id,
        data: { message: remove_details, refresh_page: false },
        success(res) {
        	btn.html("Removed");
        }
      });
      setAsLoading(btn, "sm");
      btn.prop("disabled", true);
		}
	});
});

onmount('#remove_rating_and_review', function(){
	$(this).on('click', function(){
		$.ajax({
		  type: 'DELETE',
		  url: '/admin/review/remove/' + $(this).attr('model_id'),
		  data: { message: $('#remove_details').val(), lock_comments: $('#locked_comments').is(':checked'), has_spoilers: $('#has_spoilers').is(':checked'), rating: 0 }
		});
	});
});

onmount('#only_remove_rating', function(){
	$(this).on('click', function(){

		let btn = $(this);
		btn.prop('disabled', true);

		blConfirmation("Are you sure you want to remove this user's rating for this game?",
			function(){
				btn.prop('disabled', false);
			},
			function(){
				$.ajax({
				  type: 'DELETE',
				  url: '/admin/review/remove/' + btn.attr('model_id') + '/rating',
				  success(res) {
				  	toastr.success('Review rating successfully removed');
				  },
				  error(err, textStatus, errorThrown) {
				  	toastr.success('Error removing rating: ' + errorThrown);
				  }
				});
			}, "Cancel", "Remove");
	});
});

onmount('#remove_model_button', function() {
	$(this).on('click', function(){

    if($(this).attr('type') == 'review') {
      $.ajax({
        type: 'DELETE',
        url: '/admin/review/remove/' + $(this).attr('model_id'),
        data: { message: $('#remove_details').val(), lock_comments: $('#locked_comments').is(':checked'), has_spoilers: $('#has_spoilers').is(':checked') },
        success(res) {
        	toastr.success('Review successfully updated');
        },
        error(err, textStatus, errorThrown) {
        	toastr.success('Error updating review: ' + errorThrown);
        }
      });
    } else if ($(this).attr('type') == 'comment') {
      $.ajax({
        type: 'DELETE',
        url: '/admin/comment/remove/' + $(this).attr('model_id'),
        data: { message: $('#remove_details').val() },
        success(res) {
        	toastr.success('Comment successfully updated');
        },
        error(err, textStatus, errorThrown) {
        	toastr.success('Error updating comment: ' + errorThrown);
        }
      });
    } else if ($(this).attr('type') == 'list') {
    	 $.ajax({
        type: 'DELETE',
        url: '/admin/list/remove/' + $(this).attr('model_id'),
        data: { message: $('#remove_details').val() },
        success(res) {
        	toastr.success('List successfully updated');
        },
        error(err, textStatus, errorThrown) {
        	toastr.success('Error updating list: ' + errorThrown);
        }
      });
    }
	});
});

onmount('.admin-section-toggle', function() {
	$(this).on('click', function() {
		const section = $(this).attr('section');
		$('.admin-section-toggle').removeClass('toggled');
		$(this).addClass('toggled');
		$('.admin-section-toggleable').addClass('d-none');
		$('.admin-section-toggleable#admin-' + section).removeClass('d-none');
	});
});

onmount('.reactivate-user-quick', function(){
	$(this).on('click', function(){
		const user_id = $(this).attr('user_id');
		let btn = $(this);

		$.ajax({
			type: 'POST',
			url: '/reactivate/' + user_id,
			success(res) {
				toastr.success('Reactivated succesfully');
				btn.addClass('d-none');
			}
		})
	});
});