import micro_modal from 'micromodal';
import { blConfirmation, getModalType } from 'helpers.js';
import Cookies from 'js-cookie';

onmount('.game-journal-container', function(){
	const journal_date = $(this).attr('journal_date');
	let container = $(this);

	$.ajax({
		type: "GET",
		url: "/journal/friends/" + journal_date,
		success(res_html) {
			container.html(res_html);
		}
	});
});

onmount('#edit-log-title-icon', function(){
	$(this).on('click', function(){
		$('#log-title-field').prop('disabled', !$('#log-title-field').prop('disabled'));
		if ($('#log-title-field').prop('disabled')) {
			$('#edit-log-title-icon i').addClass('fa-pen-square');
			$('#edit-log-title-icon i').removeClass('fa-check-square');
		} else {
			$('#edit-log-title-icon i').removeClass('fa-pen-square');
			$('#edit-log-title-icon i').addClass('fa-check-square');
		}
	});
});

var save_review_editor = true

onmount('#expand-review-editor', function(){
	$(this).on('click', function(){
		const review_text = $('#review').val();
		$('#full_review').val(review_text);
		$('#review-editor-draft').prop('disabled', false);
		save_review_editor = true;

		micro_modal.show('review-editor-modal', {
			awaitOpenAnimation: true,
			awaitCloseAnimation: true,
			onClose: function() {
				$('#expand-review-editor').blur();
				if (save_review_editor) {
					const review_text = $('#full_review').val();
					$('#review').val(review_text);
				}
			}
		});
	});
});

onmount('#review-editor-draft', function(){
	$(this).on('click', function(){
		const review_text = $('#full_review').val();
		$(this).prop('disabled', true);

		$.ajax({
			type: "POST",
			url: "/user/review/draft",
			data: { review: review_text },
			success(res) {
				save_review_editor = false;
				micro_modal.close('review-editor-modal');
			},
			error(res) {

			}
		});
	});
});

onmount('#review-editor-close', function(){
	$(this).on('click', function(){
		micro_modal.close('review-editor-modal');
	});
});

onmount('#formatting-guide-toggle', function(){
	$(this).on('click', function(){
		$('#formatting-info').toggleClass('d-none');
		$('#formatting-guide-toggle i').toggleClass('fa-rotate-180');
	});
});

onmount('.time-checkbox-option', function(){
	$(this).on('change', function(){

		if ($(this).prop('checked')) {
			$('.time-checkbox-option').prop('checked', false);
			$(this).prop('checked', true);
			$('#log-time-played-hours, #log-time-played-minutes').prop('disabled', true);
		} else {
			$('#log-time-played-hours, #log-time-played-minutes').prop('disabled', false);
		}
	})
});

onmount('.cancel-rating', function(){
	$(this).on('click', function(){
		const game_log_id = $(this).attr('game_log_id');
		$.ajax({
			type: "DELETE",
			url: "/delete-rating/" + game_log_id
		})
	});
});

onmount('.quick-logs', function(){
	$(this).on('click', function(){
		window.location.href = "/u/" + $(this).attr('username') + "/plays/" + $(this).attr('game_id') + "/"
	});
});

onmount('.delete-log', function(){
	$(this).on('click', function(){
		const playthrough_id = $(this).attr('playthrough_id');

		blConfirmation("Delete this log?<br><br>This includes its associated review and any logged journal sessions.",
			function(){},
			function(){
				$.ajax({
					type: "DELETE",
					url: "/playthrough/" + playthrough_id,
					success(res){
						location.reload();
					}
				});
			}, 
			"Cancel", "Delete"
		);
	});
});

onmount('.library-plat-selectpicker', function(){
	const platform_id = $(this).attr('selected-value');

	$(this).html($('#game-platform-options').html());
	$(this).selectpicker('refresh');

	if ($(this).children('option[value="' + platform_id + '"]').length == 0) {
		$(this).append($('#playthrough-platform-emulated option[value="' + platform_id + '"]').clone());
		$(this).children('option[value="' + platform_id + '"]').attr('data-icon', 'fas fa-exclamation-triangle').attr('data-subtext', "removed");
		$(this).selectpicker('refresh');
	}

	$(this).selectpicker('val', platform_id);
});

onmount('#playthrough_platform', function(){
	$(this).on('changed.bs.select', function(event, clickedIndex, isSelected, previousValue){
		if ($('#playthrough-platform-emulated').attr('user-edited') === "false") {
			$('#playthrough-platform-emulated').selectpicker('val', $(this).val());
		}
	});
});

onmount('#playthrough-platform-emulated', function(){
	$(this).on('changed.bs.select', function(event, clickedIndex, isSelected, previousValue){
		$(this).attr('user-edited', isSelected === true);
	});
});

onmount('#note-privacy-dropdown .nav-link', function(){
	$(this).on('click', function(){
		$('#note-privacy-dropdown .nav-link').removeClass('current-sort');
		$('#note-privacy-button-text').html($(this).html());
		$(this).addClass('current-sort');
	});
});

onmount('#game-status-selector', function(){
	$(this).on('click', function(){
		micro_modal.show('play-type-modal', {
			awaitOpenAnimation: true,
			awaitCloseAnimation: true,
			onShow: function() {
				const currVal = $('#status').attr('value');
	    		$('#play-type-modal .play-type-option#' + currVal).addClass("filled");
			}
		});
	});
});

onmount('#play-type-modal .play-type-option', function(){
	$(this).on('click', function(){
		$('#play-type-modal .play-type-option').removeClass("filled");
		micro_modal.close('play-type-modal');
		$('#status').val($(this).attr('id'));
		$('#play-type-field').html($(this).attr('id'));
		$('#played-status-selectors #game-status-selector').attr('class', 'btn-link play-type-bkg ' + $(this).attr('id'));
		$('#played-status-selectors #play-toggle-label').attr('class', 'play-type-bkg ' + $(this).attr('id'))
		$('#play-label-title').html($(this).attr('title'));
		$('#play_toggle_checkbox').prop('checked', true);
	});
});

onmount('#log-advanced-section-control', function(){
	$(this).on('click', function(){
		$(this).toggleClass('fa-rotate-180');
		$('#log-advanced-section').toggleClass('d-none');
		Cookies.set('advanced_open', !$('#log-advanced-section').hasClass('d-none'), { sameSite: 'strict', expires: 365 });

		if (getModalType() !== "mobile") {
			if ($('#btn-destroy-all-logs-v2').attr('log_id') && $('#btn-destroy-all-logs-v2').attr('log_id') !== "") {
				$('#btn-destroy-all-logs-v2').toggleClass('d-none');
			} else {
				$('#btn-destroy-all-logs-v2').addClass('d-none');
			}
		}
	});
});

onmount('#log-dates-section-control', function(){
	$(this).on('click', function(){
		$('#log-dates-section').toggleClass('d-none');
	});
});