var confirmation_cancelled = true;

export function hideSidebar() {
    $('.sidebar').removeClass('active');
    $('.sidebar-overlay').removeClass('active');
    $('body').removeClass('noScroll');
}

export function getModalType() {
    return $('#journal-game-modal .modal-body').attr('type');
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function setAsLoading(element, size="md") {
    const sizeClass = size === "sm" ? "spinner spinner-sm" :  "spinner";
    element.html('<div class="' + sizeClass + '"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>');
}

export function blConfirmationPassword(
    warningText,
    onAbort,
    onSave,
    abortText="Discard",
    saveText="Save"
    ) {

    blUnsavedWarning(warningText, onAbort, onSave, onAbort, abortText, "", saveText, true, true);
};

export function blConfirmation(
    warningText,
    onAbort,
    onSave,
    abortText="Discard",
    saveText="Save"
    ) {

    blUnsavedWarning(warningText, onAbort, onSave, onAbort, abortText, "", saveText, true);
};

export function blUnsavedWarning(
    warningText,
    onAbort,
    onSave,
    onCancel=function(){},
    abortText="Discard",
    cancelText="Return to edit",
    saveText="Save",
    hideCancel=false,
    verifyPassword=false
    ) {
    $('#journal-warning-modal #warning-desc').html(warningText);
    $('#journal-warning-modal #warning-abort').text(abortText);
    $('#journal-warning-modal #warning-cancel').text(cancelText);
    $('#journal-warning-modal #warning-save').text(saveText);

    if (hideCancel) {
        $('#journal-warning-modal #warning-cancel').addClass('d-none');
    } else {
        $('#journal-warning-modal #warning-cancel').removeClass('d-none');
    }

    if (verifyPassword) {
        $('#journal-warning-modal #password-section').removeClass('d-none');
        $('#verify-password-incorrect').addClass('d-none');
    } else {
        $('#journal-warning-modal #password-section').addClass('d-none');
    }

    MicroModal.show('journal-warning-modal', {
        awaitOpenAnimation: true,
        awaitCloseAnimation: true,
        onClose: function(){
            if (confirmation_cancelled) {
                onCancel();
            }
            $('#warning-verify-password').val('');
            confirmation_cancelled = true;
        }
    });

    $('#warning-abort').off("click").one("click", function() {
        onAbort();
        confirmation_cancelled = false;
        MicroModal.close('journal-warning-modal');
    });

    $('#warning-save').off("click").on("click", function() {
        if (verifyPassword) {
            const password = $('#warning-verify-password').val();

            if (password.length < 1) {
                return;
            }

            $.ajax({
                type: "POST",
                url: "/user/password/verify/",
                data: { password: password },
                success(res){
                    onSave();
                    confirmation_cancelled = false;
                    MicroModal.close('journal-warning-modal');
                },
                error(res){
                    $('#warning-save').text(saveText);
                    $('#warning-save').prop('disabled', false);
                    $('#verify-password-incorrect').removeClass('d-none');
                }
            });
            setAsLoading($('#warning-save'), 'sm');
            $('#warning-save').prop('disabled', true);
        } else {
            onSave();
            confirmation_cancelled = false;
            MicroModal.close('journal-warning-modal');
        }
    });
};