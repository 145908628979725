import onmount from 'onmount';

onmount('.like-game-btn, .quick-game-like', function(){
	$(this).on('click', function(){
		const is_liked = $(this).hasClass('toggled');
		const game_id = $(this).attr('game_id');
		let like_btn = $(this);
		like_btn.prop('disabled', true);

		if (is_liked) {
			$.ajax({
	            type: "DELETE",
	            url: "/unlike/game/" + game_id,
	            success(res) {
	                like_btn.prop('disabled', false);
	            },
	        });
		} else {
			$.ajax({
	            type: "POST",
	            url: "/like/game/" + game_id,
	            success(res) {
	                like_btn.prop('disabled', false);
	            },
	        });
		}


		$(this).toggleClass('toggled');
		$(this).blur();
	});
})