const tippy = require('tippy.js').default;
import { hideAll } from 'tippy.js';

function hideEditControls(comment_id) {
    $('.comment-options-btn[comment_id="' + comment_id + '"]').removeClass('d-none');
    $('#comment-' + comment_id + ' #comment-body-edit').remove();
    $('#comment-' + comment_id + ' .comment-body').removeClass('d-none');
    $('#comment-' + comment_id + ' .comment-editing-mode').addClass('d-none');
}

onmount('.comment-options-btn', function(){
	const content = $('#comment-options-btn-container-' + $(this).attr('comment_id'));
	const tippy_instance = tippy($(this)[0], {
        delay: [50, null],
        offset: [8, 0],
        hideOnClick: true,
        interactive: true,
        interactiveBorder: 15,
        animation: 'shift-away-subtle',
        trigger: 'mouseenter click',
        allowHTML: true,
        placement: 'right-end',
        theme: 'backloggd-quick-access',
        content: $(content).html(),
        onShow(instance) {
        	hideAll({exclude: instance});
        },
        onShown(instance) {
        	onmount();
        }
    });
});

onmount('.quick-access-bar-more .comment-edit-btn', function(){
	$(this).on('click', function(){
    	const tippy_instance = $('.comment .comment-options-btn[comment_id="' + $(this).attr('comment_id') +'"]')[0]._tippy;
    	tippy_instance.hide();

		const comment_id = $(this).attr('comment_id');
		const comment_body = $('#comment-' + comment_id + ' .comment-body-unformatted').text();
		$('.comment-options-btn[comment_id="' + comment_id + '"]').addClass('d-none');
 		$('#comment-' + comment_id + ' .comment-body-container').append("<div class='col' id='comment-body-edit'><textarea type='text' rows='2' class='backloggd-field w-100'></div>");
 		$('#comment-' + comment_id + ' #comment-body-edit textarea').val(comment_body);
 		$('#comment-' + comment_id + ' .comment-body').addClass('d-none');
 		$('#comment-' + comment_id + ' .comment-editing-mode').removeClass('d-none');
	});
});

onmount('.comment-edit-cancel-btn', function(){
	$(this).on('click', function(){
		const comment_id = $(this).attr('comment_id');
        hideEditControls(comment_id);
	});
});

onmount('.comment-edit-confirm-btn', function(){
	$(this).on('click', function(){
		const comment_id = $(this).attr('comment_id');
		const comment_body = $('#comment-' + comment_id + ' #comment-body-edit textarea').val();
		let confirm_btn = $(this);

        const existing_comment_body = $('#comment-' + comment_id + ' .comment-body-unformatted').text();
        if (comment_body == existing_comment_body) {
            hideEditControls(comment_id);
            return;
        }

		confirm_btn.prop('disabled', true);
		$('.comment-edit-confirm-btn[comment_id="' + comment_id + '"] .loading-icon').removeClass('d-none');
		$('.comment-edit-confirm-btn[comment_id="' + comment_id + '"] .confirm-icon').addClass('d-none');

		$.ajax({
			type: 'POST',
			url: '/comment/edit/' + comment_id,
			data: { comment_body: comment_body },
			success: function(res, status) {
				$('#comment-' + comment_id + ' .comment-body-unformatted').text(comment_body)
		 		$('#comment-' + comment_id + ' .comment-body').text(comment_body);
                hideEditControls(comment_id);

		 		$('.comment-edit-confirm-btn[comment_id="' + comment_id + '"] .loading-icon').addClass('d-none');
				$('.comment-edit-confirm-btn[comment_id="' + comment_id + '"] .confirm-icon').removeClass('d-none');
				confirm_btn.prop('disabled', false);

                // Don't show if comment content was the same
                if (status != "nocontent") {
    				toastr.success('Your comment has been updated');
                }
			},
			error: function(req, status, err) {
				if (err == 'Bad Request') {
					toastr.error('Your comment cannot be blank.');
				} else {
					toastr.error('Failed to update your comment.');
				}

				$('.comment-edit-confirm-btn[comment_id="' + comment_id + '"] .loading-icon').addClass('d-none');
				$('.comment-edit-confirm-btn[comment_id="' + comment_id + '"] .confirm-icon').removeClass('d-none');
				confirm_btn.prop('disabled', false);
			}
		});
	});
});

onmount('.quick-access-bar-more .delete-comment-btn', function(){
	$(this).on('click', function(){
        const tippy_instance = $('.comment .comment-options-btn[comment_id="' + $(this).attr('comment_id') +'"]')[0]._tippy;
        tippy_instance.hide();

        $(this).prop("disabled", true);
        let res = confirm("Are you sure you'd like to delete this comment?");
        if (res == true) {
            const comment_id = $(this).attr('comment_id');
            $.ajax({
                type: "DELETE",
                url: "/comment/destroy/" + comment_id,
                success(res){
                	$('#comment-' + comment_id).remove();
                	toastr.success('Comment removed');
                }
            });
        } else {
            $(this).prop("disabled", false);
        }
	});
});

onmount('#comment-submit', function(){
	$(this).on('click', function(){
		const model_id = $(this).attr('model_id');
		const model_type = $(this).attr('model_type');
		const comment_body = $('#comment_body').val();

		$(this).prop('disabled', true);
    	$(this).html('Sending...');

		$.ajax({
			type: 'POST',
			url: '/comment/' + model_type + '/new/' + model_id,
			data: { comment_body: comment_body },
			success: function(res) {
				$('#comment-submit').prop('disabled', false);
    			$('#comment-submit').html('Comment');
    			$('#comment_body').val('');
				toastr.success('Your comment has been posted');
			},
			error: function(req, status, err) {
				if (err == 'Bad Request') {
					toastr.error('Your comment cannot be blank.');
				} else {
					toastr.error('Failed to post your comment.');
				}

				$('#comment-submit').prop('disabled', false);
				$('#comment-submit').html('Comment');
			}
		});
	});
});

onmount('#display-removed-comments', function(){
	$(this).on('click', function(){
		$(this).addClass('d-none');
		$('.comment-hidden').removeClass('d-none');
	});
});

onmount('#appeal-comment-submit', function(){
	$(this).on('click', function(){
		const review_id = $(this).attr('review_id');
		const comment_body = $('#appeal_comment_body').val();
		const from_mod = $(this).attr('from_mod');

		$(this).prop('disabled', true);
    	$(this).html('Sending...');

		$.ajax({
			type: 'POST',
			url: '/comment/appeal/new/' + review_id,
			data: { comment_body: comment_body, from_mod: from_mod },
			success: function(res) {
				$('#appeal-comment-submit').prop('disabled', false);
    			$('#appeal-comment-submit').html('Comment');
    			$('#appeal-comment-submit').val('');
				toastr.success('Your comment has been submitted');
				window.location.reload();
			}
		});
	});
});