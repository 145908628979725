import MicroModal from 'micromodal';
import { hideAll } from 'tippy.js';

onmount('#removed-review-submit', function(){
  $(this).on('click', function(){
    const body = $('#removed_review_body').val();
    const review_id = $(this).attr('review_id');
    $(this).prop('disabled', true);

    $.ajax({
      type: "PUT",
      url: '/appeal/review/' + review_id + '/edit/',
      data: { body: body },
      success(res){
        toastr.success('Review submitted');
        $.ajax({
          type: 'POST',
          url: '/comment/appeal/new/' + review_id,
          data: { comment_body: 'Review edits submitted', from_mod: false },
          success: function(res) {
            window.location.reload();
          }
        });
      },
      error(err, textStatus, errorThrown) {
        toastr.success('Error submitting review edits: ' + errorThrown);
      }
    })
  });
});

onmount('.close-report-modal', function(){
    $(this).on('click', function() {
        MicroModal.close('report-modal');
    });
});

onmount('.report-nav', function(){
    $(this).on('click', function(){
        $('.report-nav').removeClass('filled');
        $(this).addClass('filled');

        if($(this).attr('id') == 'block') {
            $('.report-desc').addClass('d-none');
            $('.block-desc').removeClass('d-none');
        } else if($(this).attr('id') == 'report') {
            $('.report-desc').removeClass('d-none');
            $('.block-desc').addClass('d-none');
        }
    });
});

onmount('.report-open', function(){
  $(this).on('click', function(){
    const report_id = $(this).attr('report_id');

    $.ajax({
      type: 'GET',
      url: '/report/open-modal/' + report_id,
      success(res) {
        $('#report-case-modal .modal__container').html(res);
        onmount();
        MicroModal.show('report-case-modal', {
          awaitOpenAnimation: true,
          awaitCloseAnimation: true,
          debugMode: true
        });
      }
    })
  });
});

onmount('.selectpicker.report-status-picker', function(){
  $(this).on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue){

    const report_id = $(this).attr('report_id');

    $.ajax({
      type: 'PATCH',
      url: '/report/status/' + report_id,
      data: {status: $(this).val()},
      success(res) {
        toastr.success('Report updated');
      }
    });
  });
});

onmount('.report-game-btn', function(){
  $(this).on('click', function(){
    const game_id = $(this).attr('game_id');

    $('#submit-game-report-btn').attr('game_id', game_id);

    MicroModal.show('report-game-modal', {
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
  });
});

onmount('.report-btn', function(){
	$(this).on('click', function(){
		const model_type = $(this).attr('model_type');
		const model_id = $(this).attr('model_id');
    const user_id = $(this).attr('user_id');

    // This closes all tippy instances, mainly used for comment context menu
    hideAll();

		$('#submit-report-btn').attr('model_type', model_type).attr('model_id', model_id);
    $('#block-btn').attr('user_id', user_id);

    const report_reasons = {
      'game': ['Duplicate', 'Missing / Invalid Data'],
      'review': ['Abuse / Harassment', 'Unmarked Spoiler', 'Spam', 'Other'],
      'comment': ['Abuse / Harassment', 'Spam', 'Other'],
      'user': ['Abuse / Harassment', 'Offensive Username', 'Spam', 'Other'],
      'list': ['Abuse / Harassment', 'Spam', 'Other']
    }

    if (model_type == 'game') {
      $('#report-modal #block').addClass('d-none');
    } else {
      $('#report-modal #block').removeClass('d-none');
    }

    $('#report-modal #report_reasoning').html('');
    $.each(report_reasons[model_type], function(index, option){
      $('#report-modal #report_reasoning').append('<option>' + option + '</option>');
    });
    
    $('#report_reasoning').selectpicker('refresh');

    MicroModal.show('report-modal', {
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
    });
	});
});

onmount('#report_reasoning', function(){
  $(this).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    const model_type = $('.report-btn').attr('model_type');

    if(isSelected) {
      $('#submit-report-btn, #submit-game-report-btn').prop("disabled", false);
    } else {
      $('#submit-report-btn, #submit-game-report-btn').prop("disabled", true);
    }

    if(model_type == 'game' && clickedIndex == 2) {
      $('#report_body, #submit-report-btn').addClass('d-none');
      $('#report-invalid-game-info').removeClass('d-none');
    } else {
      $('#report_body, #submit-report-btn').removeClass('d-none');
      $('#report-invalid-game-info').addClass('d-none');
    }
  });
});

onmount('#unblock-btn', function(){
   $(this).on('click', function(){
    const user_id = $(this).attr('user_id');

    $.ajax({
      type: 'DELETE',
      url: '/unblock/' + user_id,
      success(res) {
        location.reload();
      }
    });
  });
})

onmount('#block-btn', function() {
  $(this).on('click', function(){
    const user_id = $(this).attr('user_id');

    $.ajax({
      type: 'POST',
      url: '/block/',
      data: { user_id: user_id },
      success(res) {
        location.reload();
      }
    });

      
    MicroModal.close('report-modal');
  });
});

onmount('#submit-report-btn', function(){
	$(this).on('click', function(){
		const model_type = $(this).attr('model_type');
		const model_id = $(this).attr('model_id');
		const reason = $('#report-modal #report_reasoning').val();
		const desc = $('#report-modal #report_body').val();

    $('#submit-report-btn').prop("disabled", true);

		$.ajax({
			type: 'POST',
			url: '/report/',
			data: { model_type: model_type, model_id: model_id, reason: reason, desc: desc },
			success(res) {
				toastr.success('Report has been received');
        $('#report-modal #report_body').val('');
			}
		});
		
		MicroModal.close('report-modal');
	});
});

onmount('#submit-game-report-btn', function(){
  $(this).on('click', function(){
    const game_id = $(this).attr('game_id');
    const reason = $('#report-game-modal #report_reasoning').val();
    const desc = $('#report-game-modal #report_body').val();

    $('#submit-game-report-btn').prop("disabled", true);

    $.ajax({
      type: 'POST',
      url: '/report/',
      data: { model_type: 'game', model_id: game_id, reason: reason, desc: desc },
      success(res) {
        toastr.success('Your report has been received');
        $('#report-game-modal #report_body').val('');
      }
    });
    
    MicroModal.close('report-game-modal');
  });
});