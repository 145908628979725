const tippy = require('tippy.js').default;
import {animateFill} from 'tippy.js';

import onmount from 'onmount';

onmount('.journal-quick-icons .play-session-note', function(){
	const content = $(this).next()[0];

	tippy($(this)[0], {
		animateFill: true,
		allowHTML: true,
		placement: 'top',
		plugins: [animateFill],
		theme: 'backloggd',
		content: content.innerHTML,
		popperOptions: {
		    strategy: 'fixed',
		    modifiers: [
		      {
		        name: 'flip',
		        enabled: false
		      }
		    ],
	  	},
	});
});

onmount('.card.game-cover.user-rating', function() {
	const content = $('#tippy-ratings-display');
	tippy($(this)[0], {
	    content: $(content).html(),
	    allowHTML: true,
	    placement: 'top',
	    offset: [0, -13],
		theme: 'backloggd-rating',
		arrow: false,
	    onShow(instance) {
	    	const rating = $(instance.reference).attr('data-rating');
	    	instance.setContent(instance.props.content.replace('width:0%', 'width:' + (rating * 10) + "%"));
	    },
	    popperOptions: {
		    strategy: 'fixed',
		    modifiers: [
		      {
		        name: 'flip',
		        enabled: false
		      }
		    ],
	  	},
	});
});

onmount('.mastered-icon-tooltip', function(){
	tippy($(this)[0], {
		content: "Mastered",
		animateFill: true,
		plugins: [animateFill],
		offset: [0, 5],
		placement: 'top',
		theme: 'backloggd',
		allowHTML: true,
		popperOptions: {
		    strategy: 'fixed',
		    modifiers: [
		      {
		        name: 'flip',
		        enabled: false
		      }
		    ],
	  	},
	});
});

onmount('.time-tooltip', function() {
	tippy($(this)[0], {
		animateFill: true,
		plugins: [animateFill],
		offset: [0, 5],
		placement: 'top',
		theme: 'backloggd',
		delay: [400, null],
		allowHTML: true,
		popperOptions: {
		    strategy: 'fixed',
		    modifiers: [
		      {
		        name: 'flip',
		        enabled: false
		      }
		    ],
	  	},
	});
});

onmount('.top-tooltip, #friend_plays [data-tippy-content], #log-counters [data-tippy-content], #play-ratings [data-tippy-content], #backlog-ratings [data-tippy-content]', function(){
	tippy($(this)[0], {
		animateFill: true,
		plugins: [animateFill],
		offset: [0, 5],
		placement: 'top',
		theme: 'backloggd',
		popperOptions: {
		    strategy: 'fixed',
		    modifiers: [
		      {
		        name: 'flip',
		        enabled: false
		      }
		    ],
	  	},
	});
});

let zIndexTop = 3000;

onmount('.badge-tooltip', function(){

	const template = document.getElementById("badge-" + $(this).attr('badge_id'));
	const badge_el = $(this);

	tippy($(this)[0], {
		content: template.innerHTML,
		animation: 'scale-subtle',
		// interactive: true,
		allowHTML: true,
		offset: [0, -45],
		placement: "right",
		theme: 'backloggd-badge',
		zIndex: zIndexTop,
		trigger: 'mouseenter click',
		onTrigger(instance, event) {
			instance.setProps({zIndex: zIndexTop});
			badge_el.css('z-index', zIndexTop + 1);
			badge_el.css('position', 'relative');
			zIndexTop += 2;
		},
		onHidden(instance, event) {
			badge_el.css('position', 'static');
		},
	});	
});

onmount('.right-tooltip', function(){
	tippy($(this)[0], {
		animateFill: true,
		plugins: [animateFill],
		offset: [0, 5],
		placement: "right",
		theme: 'backloggd'
	});	
});

onmount('.helper-tooltip', function() {
	tippy($(this)[0], {
		animateFill: true,
		plugins: [animateFill],
		offset: [0, 5],
		theme: 'backloggd-helper',
		trigger: 'click'
	});
});