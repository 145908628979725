// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import $ from 'jquery';
global.$ = jQuery;

import 'css/site'

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

require("turbolinks").start()
require("@rails/ujs").start();
require("@rails/activestorage").start()
require('jquery')
require("devbridge-autocomplete");
require('@popperjs/core')
require('popper.js')
require('bootstrap')
require("tippy.js");
require('@fullcalendar/core')
require('@fullcalendar/daygrid')
require('@fullcalendar/interaction')
require("readmore-js")
require("bootstrap-datepicker")
require("bootstrap-select")
require("micromodal")
require("onmount")
require("progressbar.js")
require("js-cookie");
require('toastr');
require('throttle-debounce');
require('local-time').start();
require('@yaireo/tagify');
require('nouislider')

require("main");
require("plays");
require("search-bars");
require("tooltips");
require("account");
require("lists");
require("modals");
require("filters");
require("friends");
require("progress-bar");
require("report");
require("admin");
require("stats");
require('comments');
require('journal');
require('patreon');
require('quick-log');

