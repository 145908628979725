import toastr from 'toastr';
import Rails from '@rails/ujs';
import { blConfirmation, blUnsavedWarning, blConfirmationPassword, setAsLoading } from 'helpers.js';
import { Draggable, Sortable, Plugins } from "@shopify/draggable";

var resetWidth = true;
var sortable = null;
var widget_options = [{}, {}, {}]

onmount('#quick-access-toggle', function(){
	$(this).on('click', function(){
		$('#quick-access-widget-bar').addClass('d-none');
		const user_id = $(this).attr('user_id');

		$.ajax({
				type: 'PATCH',
				url: '/users/' + user_id,
				data: { user_settings: { display_quick_access: false } },
				success(res_html) {
					toastr.success('Widgets hidden, update preference in your profile settings');
				}
			});
	});
});

onmount('#widget-editor-toggle', function(){
	$(this).on('click', function(){
		MicroModal.show('widget-edit-modal', {
		    awaitOpenAnimation: true,
		    awaitCloseAnimation: true,
		    disableScroll: true,
		    onClose:  function(){
				$('#widget-selector, #widget-preview').addClass('d-none');
				$('#quick-access-viewer').removeClass('d-none');
		    }
    	});
	});
});

onmount('#widget-editor-close', function(){
	$(this).on('click', function(){
		MicroModal.close('widget-edit-modal');
	});
});

onmount('.widget-slot-edit', function(){
	$(this).on('click', function(){
		const type = $(this).attr('widget_type');

		$('#widget-selector').removeClass('d-none');
		$('#widget-preview').addClass('d-none');
		$('#quick-access-viewer').addClass('d-none');
		$('#widget-edit-modal .widget-option').removeClass('current');
		$('#widget-edit-modal .widget-option[widget_type="' + type + '"]').addClass('current');
		$('#widget-use').attr('slot_num', $(this).attr('slot'));
	});
});

onmount('#widget-selector .widget-option', function(){
	$(this).on('click', function(){
		const widget_img_url = $('.widget-preview img', this).attr('src');
		const widget_title = $('.quick-access-widget-title .title-text', this).text();
		const widget_desc = $('.quick-access-widget-desc', this).text();
		const widget_premium = $(this).attr('is_premium') == "true";
		const user_has_premium = $(this).attr('has_premium') == "true";

		$('#widget-use').attr('widget_type', $(this).attr('widget_type'));
		$('#widget-preview img').attr('src', widget_img_url);
		$('#selected-widget-title, #widget-preview .quick-access-widget-title').text(widget_title);
		$('#selected-widget-desc').text(widget_desc + ".");
		$('#widget-selector').addClass('d-none');
		$('#widget-preview').removeClass('d-none');

		if($(this).attr('is_customizable') == 'true') {
			let container = $('#widget-customization-section #options-container');
			$.ajax({
				type: "GET",
				url: "/widget/settings/render/",
				data: { widget_type: $(this).attr('widget_type'), widget_slot: $('#widget-use').attr('slot_num') },
				success(res_html) {
					container.html(res_html);

					if (!user_has_premium) {
						container.append('<div id="widget-premium-wall"></div>')
					}

					const existing_options = widget_options[$('#widget-use').attr('slot_num')][$(this).attr('widget_type')];

					if (existing_options) {
						existing_options.each(function(){
							const type = $('#options-container #' + $(this).id).prop('type');

							// checked radios/checkboxes
				      if (type == "checkbox" || type == "radio") { 
				        $('#options-container #' + $(this).id).prop('checked', $(this).val);
				      }
				      // all other fields, except buttons
				      else if (type != "button" && type != "submit") {
	           		$('#options-container #' + $(this).id).val($(this).val);
				      }
						});
					}

					onmount();
				}
			});
			$('#widget-customization-section').removeClass('d-none');
		} else {
			$('#widget-customization-section #options-container').html('');
			$('#widget-customization-section').addClass('d-none');
		}

		$('#widget-use').prop('disabled', (widget_premium && !user_has_premium));
	});
});

onmount('.widget-go-back', function(){
	$(this).on('click', function(){
		// If user is on the widget preview screen, go back to widget selector
		if ($('#widget-selector').hasClass('d-none')) {
			$('#widget-selector').removeClass('d-none');
			$('#widget-preview').addClass('d-none');
			$('#widget-customization-section #options-container').html(''); // Clear options
		// If user is on the widget selector, go back to first widget screen
		} else {
			$('#widget-selector').addClass('d-none');
			$('#quick-access-viewer').removeClass('d-none');
		}
	});
});

onmount('#widget-use', function(){
	$(this).on('click', function(){
		$('.widget-slot-edit[slot="' + $(this).attr('slot_num') + '"]').attr('widget_type', $(this).attr('widget_type'));
		$('.widget-slot-edit[slot="' + $(this).attr('slot_num') + '"] .widget-slot-edit-title').text($('#widget-preview .quick-access-widget-title').text());
		const widget_type = $(this).attr('widget_type');
		const slot_num = $(this).attr('slot_num');

		const customization_options = $('#options-container :input').map(function(){
			const type = $(this).prop('type');

			// checked radios/checkboxes
			if (type == "checkbox" || type == "radio") { 
				return {
					id: $(this).attr('id'),
					val: this.checked,
				}
			}
			// all other fields, except buttons
			else if (type != "button" && type != "submit") {
				return {
					id: $(this).attr('id'),
					val: $(this).val(),
				}
			}
		}).get(); // get returns an array

		$('#widget-selector').addClass('d-none');
		$('#widget-preview').addClass('d-none');
		$('#quick-access-viewer').removeClass('d-none');

		$.ajax({
			type: "POST",
			url: "/widget/set/",
			data: { widget_options: customization_options, widget_type: widget_type, slot_num: slot_num },
			success: function(res) {
				$('#widget-' + slot_num + ' .quick-access-widget-title').text($('#widget-preview .quick-access-widget-title').text());

				$.ajax({
					type: 'GET',
					url: '/widget/render/',
					data: { slot_num: slot_num },
					success(res_html) {
						$('#widget-' + slot_num + ' .quick-access-container .col').html(res_html);
					}
				});
			}
		});
	});
});

onmount('#widget-list-selector', function(){
	$(this).on('click', function(){
		$.ajax({
			type: "GET",
			url: "/render/user_lists",
			data: { game_id: $(this).attr('game_id'), single_mode: true },
			success: function(res){
				$('#widget-list-modal #list-container').html(res);
				MicroModal.show('widget-list-modal', {
			    awaitOpenAnimation: true,
			    awaitCloseAnimation: true,
			    disableScroll: true,
		    });
			}
		});
		
	});
});

onmount('#widget-list-modal .list-entry', function(){
	$(this).on('click', function(){
		MicroModal.close('widget-list-modal');
		$('#options-container #widget-list-id').val($(this).attr('list_id'));
		$('#options-container #widget-list-selector #list-title').text($('.list-entry-title', this).text());
	});
})

// Only load when on list edit page
onmount('.draggable-list#edit-favorite-games', function() {
  if(sortable != null) {
    sortable.destroy();
  }
  sortable = new Sortable(document.querySelectorAll('#edit-favorite-games'), {
    draggable: '.draggable-list-entry',
    handle: ".list-entry-handle",
    swapAnimation: {
      duration: 150,
      easingFunction: 'ease-in-out',
      horizontal: true
    },
    plugins: [Plugins.SwapAnimation]
  });

  sortable.on('sortable:sort', () => {
      if (resetWidth) {
          $('.draggable-mirror').width($('.draggable-list-entry .grid-mode').width());
          resetWidth = false;
      }
      console.log("Sorting lists...");
  });

  sortable.on('sortable:stop', (evt) => {
    resetWidth = true;
    moveElement($(evt.newContainer).children('.draggable-list-entry'), curr_entry, evt.oldIndex, evt.newIndex);
    curr_entry = null;
  });

  sortable.on('sortable:start', (evt) => {
    curr_entry = $(evt.startContainer).children('.draggable-list-entry').get(evt.startIndex).getAttribute('entry_id');
  });
});

onmount('#add-favorite-game', function(){
    $(this).on('click', function() {
        $("#add-a-favorite-modal").modal('show');
    });
})

onmount('.delete-favorite-entry', function(){
	$(this).on('click', function(){
		const entry = $(this).parent().parent().parent().parent().parent();
		entry.remove();
		if ($('#edit-favorite-games .draggable-list-entry').length < 5) {
			$('#edit-favorite-games #add-favorite-card').removeClass('d-none');
		}
	});
});

onmount('.set-favorite-crown-entry', function(){
	$(this).on('click', function(){
		const entry = $(this).parent().parent().parent().parent().parent();
		const curr_crowned = entry.attr('crown');

		$('.draggable-list-entry').removeClass('crowned');
		$('.draggable-list-entry').attr('crown', false);

		if (curr_crowned == "false") {
			entry.addClass('crowned');
			entry.attr('crown', true);
		} else {
			entry.removeClass('crowned');
			entry.attr('crown', false);
		}
	});
});

onmount('#register-new-user-btn', function(){
	$(this).on('click', function(){
		$(this).prop('disabled', true);
		setAsLoading($(this));

		$('form#new_user').submit();
	});
});

onmount('#set-all-journal-privacy', function(){
	$(this).on('click', function(){
		const newPrivacyText = $("button[data-id='reset_note_privacy']").attr('title');
		const newPrivacy = $('#reset_note_privacy').val()

		blConfirmationPassword("Set all play session note privacy settings to: '" + newPrivacyText + "'",
			function(){},
			function(){
				$.ajax({
					type: "POST",
					url: "/logs/sessions/privacy",
					data: { new_privacy: newPrivacy },
					success(res) {
						toastr.success('Privacy settings successfully updated');
					}
				});
			}, "Discard","Confirm");
	});
});

onmount('#remove-all-tags', function(){
	$(this).on('click', function(){
		blConfirmationPassword("Remove all tags from every journal play session?",
			function(){},
			function(){
				$.ajax({
					type: "DELETE",
					url: "/user/tags/destroy",
					success(res) {
						toastr.success('Tags successfully deleted');
					}
				});
			}, "Discard","Delete All");
	});
});

onmount('#delete-account', function(){
	$(this).on('click', function(){
		const wipe_btn = $(this);
		const user_id = $(this).attr('user_id');
		wipe_btn.prop('disabled', true);

		blConfirmationPassword("You will be logged out and all data associated with your account will be permanently deleted from our database. This cannot be undone.",
			function(){
				wipe_btn.prop('disabled', false);
			},
			function(){
				$.ajax({
					type: "DELETE",
					url: "/users/" + user_id + "/",
				})
			}, "Cancel", "Delete account");
	});
});

onmount('#deactivate-account', function(){
	$(this).on('click', function(){
		const wipe_btn = $(this);
		wipe_btn.prop('disabled', true);

		blConfirmationPassword("You will be logged out and will not be able to log back in without reactivation.",
			function(){
				wipe_btn.prop('disabled', false);
			},
			function(){
				$.ajax({
					type: "DELETE",
					url: "/users",
				})
			}, "Cancel", "Deactivate");
	});
});

onmount('#wipe-all-logs', function(){
	$(this).on('click', function(){
		const wipe_btn = $(this);
		wipe_btn.prop('disabled', true);

		blConfirmationPassword("Are you 100% sure you want all your logs, reviews, journal entries, and library entries permanently deleted?",
			function(){
				wipe_btn.prop('disabled', false);
			},
			function(){
				$.ajax({
					type: "DELETE",
					url: "/user/logs/destroy/",
					success(res){
			        	toastr.success('Logs successfully scheduled for deletion');
			        	$('#logs-deletion-info').removeClass('d-none');
			      	},
				})
			}, "Cancel", "Delete my logs");
	});
});

onmount('#wipe-all-lost-logs', function(){
	$(this).on('click', function(){
		const wipe_btn = $(this);
		wipe_btn.prop('disabled', true);

		blConfirmationPassword("Are you sure you want all your lost logs with their reviews, journal entries, and library entries permanently deleted?",
			function(){
				wipe_btn.prop('disabled', false);
			},
			function(){
				$.ajax({
					type: "DELETE",
					url: "/user/lost-logs/destroy/",
					success(res){
        		toastr.success('Lost logs successfully scheduled for deletion');
	      	},
				})
			}, "Cancel", "Delete all lost logs");
	});
});

onmount('#save-integrations-btn', function(){
	$(this).on('click', function(){
		let btn = $(this);
		let form_data = new FormData($('#integrations-settings-forms')[0]);
		const user_id = $(this).attr('user_id');
		btn.prop('disabled', true);
		btn.text('Saving...');

		$.ajax({
      type: "PATCH",
      url: "/users/" + user_id + "/integrations/",
      processData: false,
      contentType: false,
      data: form_data,
      success(res){
        toastr.success('Account integrations successfully updated');
        btn.prop('disabled', false);
        btn.text('Save Changes');
        $('#gtg_create_activity').prop('disabled', false);
      },
      error(err, textStatus, errorThrown) {
      	let err_text = "[ERROR] ";

      	if (err.responseJSON) {
      		err.responseJSON.forEach(element => err_text += element + "\n");
      	} else {
      		if (err.status == 400) {
      			err_text += "Cannot save, UUID already in use."
      		} else if (err.status == 404) {
      			err_text += "Cannot save, UUID is not valid."
      		} else {
      			err_text += errorThrown;
	      	}
      	}

        toastr.error(err_text, '', {
        	"positionClass": "toast-top-center"
        });
        btn.prop('disabled', false);
        btn.text('Save Changes');
      }
    });
	});
});

onmount('#save-profile-btn', function(){
	$(this).on('click', function(){
		let form_data = new FormData($('#profile-settings-forms')[0]);
		const user_id = $(this).attr('user_id');
		let favorite_games = []
		let quick_access_log = {}

		$('#edit-favorite-games.draggable-list .draggable-list-entry').each(function(index){
      favorite_games.push({game_id: $(this).attr('game_id'), crown: $(this).attr('crown')});
    });

    $('.widget-slot-edit').each(function(index){
      quick_access_log[$(this).attr('slot')] = $(this).attr('widget_type');
    });

    form_data.append("favorites", JSON.stringify(favorite_games));
    form_data.append("quick_access", JSON.stringify(quick_access_log));

    $.ajax({
      type: "PATCH",
      url: "/users/" + user_id,
      processData: false,
      contentType: false,
      data: form_data,
      success(res){
        toastr.success('Profile successfully updated');
      },
      error(err, textStatus, errorThrown) {
      	let err_text = "Error: ";

      	if (err.responseJSON) {
      		err.responseJSON.forEach(element => err_text += element + "\n");
      	} else {
      		err_text += errorThrown;
      	}

        toastr.error(err_text, '', {
        	"positionClass": "toast-top-center"
        });
      }
    });
	});
});