import MicroModal from 'micromodal';
import Cookies from 'js-cookie';

function close(target) {
    if (!target.hasAttribute('data-close-parent-modal')) return;
    const modal = target.closest('.micro-modal');
    if (modal) MicroModal.close(modal.id);
}

onmount('body', function() {
    const hide_update_warning = Cookies.get('hide_update_warning');

    if (hide_update_warning !== 'true' && $('#apple-update-data-modal').length) {
        MicroModal.show('apple-update-data-modal', {
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            onClose: function(){
                Cookies.set('hide_update_warning', true, { sameSite: 'strict', expires: 365 });
            }
        });
    }

    $(this).on('mousedown', function(e) {
        close(e.target);
    });

    $(this).on('touchstart', function(e) {
        close(e.target);
    });
});

onmount('#new-folder-btn', function() {
    $(this).on('click', function() {
        MicroModal.show('list-folder-modal', {
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
        });
    });
});

onmount('#edit-folder-btn', function() {
    $(this).on('click', function() {
        MicroModal.show('list-folder-modal', {
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            disableFocus: true,
        });
    });
});

onmount('#add-a-game-modal', function(){
    $(this).on('shown.bs.modal', function() {
      $('#modal-game-search').focus();
    });
});

onmount('#add-a-favorite-modal', function(){
    $(this).on('shown.bs.modal', function() {
      $('#favorite-game-search').focus();
    });
});

onmount('.list-entry-note-btn', function() {
    $(this).on('click', function() {
        $('#list-entry-note').html($(this).next().html());
        $('#note-game-name').html($(this).attr('game_name'));
        MicroModal.show('list-note-modal', {
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            disableScroll: true
        });
    });
});

onmount('.edit-note', function() {
  $(this).on('click', function() {
    const list_entry = $(this).parent().parent().parent().parent().parent();
    const entry_id = list_entry.attr('entry_id');
    const note = list_entry.attr('note');
    $('#edit-list-note-modal').attr('entry_id', entry_id);
    $('#edit-list-note-modal #note').val(note);
    MicroModal.show('edit-list-note-modal', {
        awaitOpenAnimation: true,
        awaitCloseAnimation: true,
    });
  });
});

onmount('.open-log-field', function(){
    $(this).on('click', function(){
        MicroModal.show('dup-field-modal', {
        	awaitOpenAnimation: true,
			awaitCloseAnimation: true,
        });
        $('.open-log-field').attr('type', $(this).attr('type'));
        $('.open-log-field').attr('old_id', $(this).attr('log-id'));
    });
});

onmount('.submit-log-id-change', function(){
    $(this).on('click', function(){
        $.ajax({
        	type: "PATCH",
        	url: "/logs/transfer",
        	data: {type: $('.open-log-field').attr('type'), new_id: $('#log-id-transfer').val(), old_id: $('.open-log-field').attr('old_id')},
        	success: function(res) {
        		MicroModal.close('dup-field-modal');
        	}
        });
    });
});

onmount('#dup-field-modal', function(){
	MicroModal.init();
});

onmount('#edit-folders-btn', function() {
    $(this).on('click', function(){
        MicroModal.show('folder-list-selector-modal', {
            awaitOpenAnimation: true,
            awaitCloseAnimation: true,
            onShow: function(){
                $('body').addClass('modal-open');
                $('body').css('padding-right', '17px');
            },
            onClose: function(){
                const folder_count = $('#folder-list-selector-modal input[name="folder_list[]"]:checked').length;
                $('#edit-folders-btn #folder-count').html(folder_count + " Folder" + (folder_count != 1 ? "s" : ""));
                $('body').removeClass('modal-open');
                $('body').css('padding-right', '');
            }
        });
    });
});

onmount('#list-folder-selector-save', function() {
    $(this).on('click', function() {
        MicroModal.close('list-folder-selector-modal');
        let list_ids = [];

        $('#list-folder-selector-modal input[name="folder_list"]:checked').each(function() {
            list_ids.push($(this).val());
        });

        $.ajax({
            type: "PUT",
            url: "/api/list/folder/lists/",
            data: { list_ids: list_ids, folder_id: $('#list-folder-selector-modal').attr('folder_id') }
        });
    });
});

window.onmount('#add-to-list, .quick-list', function(){
	$(this).on('click', function(){
        $('#list-modal').attr('game_id', $(this).attr('game_id'));
        const button = $(this);
		$.ajax({
			type: "GET",
			url: "/render/user_lists",
			data: { game_id: $(this).attr('game_id') },
			success: function(res){
                $('#list-modal #list-container').html(res);
				MicroModal.show('list-modal', {
					awaitOpenAnimation: true,
					awaitCloseAnimation: true,
					onShow: function(){
						$('body').addClass('modal-open');
						$('body').css('padding-right', '17px');
					},
					onClose: function(){
						$('body').removeClass('modal-open');
						$('body').css('padding-right', '');
					}
				});
                button.blur();
			}
		});
	});
});
