const deferRequestByMs = 150;

onmount('#list_game_search', function(){
    $(this).autocomplete({
        minChars: 2,
        triggerSelectOnValidInput: false,
        autoSelectFirst: true,
        noCache: false,
        preventBadQueries: false,
        deferRequestBy: deferRequestByMs,
        serviceUrl: '/autocomplete.json',
        dataType: 'json',
        onSelect: function(suggestion) {
            if (suggestion && suggestion.data && suggestion.data.slug) {
                const game_id = suggestion.data.id;
                const list_id = $('.list-form')[0].id
                const list_grid_mode = $('#list_style')[0].checked

                $.ajax({
                    type: "POST",
                    url: "/api/list/" + list_id + "/" + game_id,
                    data: { grid_mode: list_grid_mode },
                });

                $('#list_game_search').val(null);
            }
            return;
        },
        formatResult: function(suggestion, currentValue) {
            return suggestion.value + " (" + suggestion.data.year + ")";
        }
    });
});


onmount('#favorite-game-search', function(){
    $(this).autocomplete({
        minChars: 2,
        triggerSelectOnValidInput: false,
        autoSelectFirst: true,
        noCache: false,
        preventBadQueries: false,
        deferRequestBy: deferRequestByMs,
        serviceUrl: '/autocomplete.json',
        dataType: 'json',
        onSelect: function(suggestion) {
            if (suggestion && suggestion.data && suggestion.data.slug) {
                const game_id = suggestion.data.id;

                $.ajax({
                    type: "POST",
                    url: "/add-favorite/" + game_id + "/"
                });
                $('#add-a-favorite-modal').modal('hide');
                $('#favorite-game-search').val(null);
            }
            return;
        },
        formatResult: function(suggestion, currentValue) {
            return suggestion.value + " (" + suggestion.data.year + ")";
        }
    });
});

onmount('#modal-game-search', function(){
    $(this).autocomplete({
        minChars: 2,
        triggerSelectOnValidInput: false,
        autoSelectFirst: true,
        noCache: false,
        preventBadQueries: false,
        deferRequestBy: deferRequestByMs,
        serviceUrl: '/autocomplete.json',
        dataType: 'json',
        onSelect: function(suggestion) {
            if (suggestion && suggestion.data && suggestion.data.slug) {
                const game_id = suggestion.data.id;
                openLogModal(game_id);
                $('#add-a-game-modal').modal('hide');
                $('#modal-game-search').val(null);
            }
            return;
        },
        formatResult: function(suggestion, currentValue) {
            return suggestion.value + " (" + suggestion.data.year + ")";
        }
    });
});

onmount('#nav-bar-search', function(){
    $(this).autocomplete({
        minChars: 2,
        triggerSelectOnValidInput: false,
        autoSelectFirst: false,
        noCache: false,
        preventBadQueries: false,
        deferRequestBy: deferRequestByMs,
        serviceUrl: '/autocomplete.json',
        dataType: 'json',
        onSelect: function(suggestion) {
            if (suggestion && suggestion.data && suggestion.data.slug) {
                Turbolinks.visit('/games/' + suggestion.data.slug + "/");
            }
            return;
        },
        formatResult: function(suggestion, currentValue) {
            return suggestion.value + " (" + suggestion.data.year + ")";
        }
    });
});

onmount('#goty-search', function(){
     $(this).autocomplete({
        minChars: 2,
        triggerSelectOnValidInput: false,
        autoSelectFirst: true,
        noCache: false,
        preventBadQueries: false,
        deferRequestBy: deferRequestByMs,
        serviceUrl: '/autocomplete/2020.json',
        dataType: 'json',
        onSelect: function(suggestion) {
            if (suggestion && suggestion.data && suggestion.data.slug) {
                const game_id = suggestion.data.id;
                const category = $("#add-a-goty-modal")[0].getAttribute("category");
                $.ajax({
                     type: "POST",
                     url: "/add-goty-entry/" + game_id + "/" + category
                 });
                $('#add-a-goty-modal').modal('hide');
            }
            return;
        },
        formatResult: function(suggestion, currentValue) {
            return suggestion.value + " (" + suggestion.data.year + ")";
        }
    });
});